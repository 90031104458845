<template>
<div class="warn-main bg-fff">
  <div class="center">告警选项</div>
  <div class="ser-form">
    <Row justify="center">
      <Col :xl="12">
        <Row class-name="pad-b24" align="middle">
          <Col span="5"></Col>
          <Col span="5">开关</Col>
          <Col span="14">告警值</Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">低电量</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="14">
            <InputNumber class="w150" :active-change="false" :precision="0" :formatter="value => `${value}%`" :parser="value => value.replace('%', '')"></InputNumber>
          </Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">SOS</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="14"></Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">摘除报警</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="14"></Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">围栏报警</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="14"></Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">跌倒</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="7">最小值</Col>
          <Col span="7">最大值</Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">血氧阈值</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">体温阈值</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="1"></InputNumber></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="1"></InputNumber></Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">心率阈值</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">舒张压阈值</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
        </Row>
        <Row class-name="pad-b24" align="middle">
          <Col span="5">收缩压阈值</Col>
          <Col span="5"><i-Switch></i-Switch></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
          <Col span="7"><InputNumber class="w150" :active-change="false" :precision="0"></InputNumber></Col>
        </Row>
      </Col>
    </Row>
  </div>
</div>
</template>

<script>
export default {
  name: "WarnSettings"
}
</script>

<style scoped>
.warn-main{
  padding: 24px;
}
.center{
  font-size: 18px;
  text-align: center;
  padding-bottom: 24px;
}
.pad-b24{
  padding-bottom: 24px;
}
.w150{
  width: 150px;
}
</style>